<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat color="white" dense>
            <v-toolbar-title>User</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-divider class="mx-4"></v-divider>
        <v-app id="inspire" class="">
            <template v-if="load == true">
                <v-data-table :headers="headers" :items="newuser_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">

                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <v-spacer></v-spacer><span style="width: 20px"></span>
                            <v-dialog v-model="dialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on"
                                        @click="iseditable = !iseditable">New User</v-btn>
                                </template>
                                <v-card>
                                    <v-form>
                                        <v-card-title>
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>

                                                    <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                        <v-autocomplete :items="user_list" v-model="editedItem.usertype"
                                                            label="Select User Type *"
                                                            :rules="[v => !!v || 'required']"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" v-if='editedIndex != -1'>User Type
                                                        <v-select dense v-model="editedItem.usertype" :items="user_list"
                                                            item-value="id" persistent-hint
                                                            :rules="[v => !!v || 'required']"></v-select>
                                                    </v-col>

                                                </v-row>
                                                <v-row dense>
                                                    <v-col cols="12" v-if='editedIndex == -1'>
                                                        <v-text-field v-model="editedItem.username" @keyup="checkUsername()"
                                                            persistent-hint label="User Name *" dense :suffix=uid_domain
                                                            :rules="[rules.rule]"></v-text-field>
                                                        <span v-if="editedIndex == -1">{{ editedItem.username }}{{
                                                            uid_domain }}</span><br>
                                                        <span v-if="editedIndex == -1"
                                                            v-bind:class="[isAvailable ? 'notavailable' : 'available']">{{
                                                                response }}</span>
                                                    </v-col>
                                                    <v-col cols="12" v-if='editedIndex != -1'>
                                                        <v-text-field v-model="editedItem.username" :disabled="true"
                                                            label="User Name" dense readonly></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <input type="hidden" v-model="editedItem.id" name="id" />
                                                        <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="6" sm="3" md="2">
                                                        <v-checkbox v-model="editedItem.isactive" color="success"
                                                            label="isActive?"></v-checkbox>
                                                    </v-col>
                                                </v-row>

                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                                            <v-btn color="primary darken-1" dark @click="save"
                                                :disabled="valid">Save</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!--<template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>-->
                    <template v-slot:item.isactive="{ item }">

                        <v-switch v-model="item.isactive" @click="activeswitch(item)"></v-switch>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-avatar style="" size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                            </v-avatar>
                        </div>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        rules: {
            rule(value) {
                // console.log('validating value');
                if (/^.[aA-zZ0-9\-]*$/.test(value)) {
                    return false;
                }
                return 'Special Characters/Symbols are not allowed!'
            }
        },
        username: '',
        isAvailable: 0,
        overlay: false,
        snackbar_msg: "",
        valid: false,
        color: "",
        snackbar: false,
        iseditable: false,
        load: false,
        dialog: false,
        response: '',
        newuser_list: null,
        user_list: null,
        UserRecord: null,
        uid_domain: '',
        search: '',
        headers: [
            {
                text: "Sr. No",
                value: "sr_no"
            },
            {
                text: "User",
                value: "username"
            },
            {
                text: "User Type",
                value: "usertype"
            },
            {
                text: "Active",
                value: "isactive"
            }, {
                text: "Actions",
                value: "action",
                sortable: false
            }],

        editedIndex: -1,
        editedItem: {
            id: 0,
            username: '',
            usertype: null,
            isactive: true,
        },
        defaultItem: {
            id: 0,
            username: '',
            usertype: '',
            isactive: true,
        }
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        overlay(val) {
            val = () => {
                this.overlay = false
            };
        },
    },
    mounted() {
        this.onload()
    },

    methods: {
        onload() {
            axios
                .post("/AdminUser/addUser")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.newuser_list = res.data.newuser_list
                        this.user_list = res.data.user_list
                        this.uid_domain = res.data.uid_domain;
                        this.load = true;
                    } else { }
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    // window.console.log(error);
                });
        },
        checkUsername() {
            var username = this.editedItem.username
            if (username != '') {
                axios
                    .post("AdminUser/checkUsername", {
                        params: {
                            username: username
                        }
                    })
                    .then(res => {
                        if (res.data.msg == "200") {
                            if (res.data.response == "Used") {
                                this.isAvailable = 1
                                this.response = res.data.responseMessage
                            }
                            else {
                                this.isAvailable = 0
                                this.response = "Username is available..."
                            }
                        }
                        else {
                            this.showSnackbar("#b71c1c", "Something went Wrong!!!");
                        }
                    });
            }
        },

        editItem(item) {
            this.iseditable = false;
            this.editedIndex = this.newuser_list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        save() {
            if (this.editedItem.usertype == "" || this.editedItem.usertype == null) {
                this.showSnackbar("#b71c1c", "Please Select User Type!!!");
                return;
            }
            else if (this.editedItem.username == "" || this.editedItem.username == null) {
                this.showSnackbar("#b71c1c", "Please Enter Username...!!!");
                return;
            }
            if (this.editedIndex > -1) {
                //  console.log("edit.." + this.editedItem);
                this.iseditable = false;
                axios
                    .post("/AdminUser/editUser", this.editedItem)
                    .then(res => {
                        if (res.data.msg == "200") {
                            Object.assign(this.newuser_list[this.editedIndex], this.editedItem);
                        }
                        this.showSnackbar(
                            "#4caf50",
                            "User Updated Successfully..."
                        ); // show snackbar on success
                    })
                    .catch(error => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        // window.console.log(error);
                    });
            } else {
                // console.log("==> " + this.uid_domain);
                // console.log("==> " + this.editedItem.username);
                this.editedItem.username += this.uid_domain
                axios
                    .post("/AdminUser/saveUser", this.editedItem)
                    .then(res => {
                        if (res.data.status.code == "SUCCESS") {
                            this.newuser_list.push(this.editedItem);
                            this.showSnackbar("#4caf50", "New User Added Successfully!!!"); // show snackbar on success
                        } else if (res.data.status.code == "NA") {
                            //alert(res.data.status.message)
                            this.showSnackbar("#b71c1c", "User Already Present!!!"); // show snackbar on error
                        }
                    })
                    .catch(error => {
                        window.console.log(error);
                    });
            }
            this.close();
        },
        activeswitch(item) {

            const data = {
                deleteitem: item.id,
            };

            axios
                .post("/AdminUser/activeUser", data)
                .then((res) => {
                    // console.log("res.data");
                    // console.log(res.data);
                    if (res.data.msg == "200") {

                        this.showSnackbar("#4caf50", res.data.message);
                        this.onload();
                    } else {

                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
            this.close();
        },
    }
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.available {
    color: green;
}

.notavailable {
    color: red;
}
</style>